import { defineStore } from "pinia";
import type { LocationQueryRaw } from "vue-router";

interface BreadcrumbState {
  breadcrumbs: {
    path: String;
    title: String;
    name: String;
    query?: LocationQueryRaw;
  }[];
}

export const useBreadcrumbStore = defineStore("breadcrumb", {
  state: (): BreadcrumbState => ({
    breadcrumbs: [],
  }),
});
