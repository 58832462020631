import { createApp, ref } from "vue";
import { router } from "./router";
import App from "./App.vue";
import { createPinia } from "pinia";
import "element-plus/dist/index.css";
import "./assets/font-icon.css";
import "./assets/main.scss";
import "animate.css";
import "nprogress/nprogress.css";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import "default-passive-events";
import myDirective from "@/utils/directive";
const ytColor = ref("red");
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App);
myDirective(app);
app.use(router).use(pinia);
app.mount("#app");
