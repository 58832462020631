<template>
  <el-dialog
    v-model="visible"
    class="login"
    width="420"
    align-center
    @close="close"
  >
    <div class="box">
      <div>
        <div
          style="
            text-align: center;
            font-size: 22px;
            color: #1f2126;
            font-weight: bold;
            margin-bottom: 32px;
          "
        >
          手机验证码登录
        </div>
        <el-form
          ref="form"
          :rules="rules"
          :model="formData"
          label-width="0"
          size="large"
        >
          <el-form-item
            prop="phone"
            style="margin-bottom: 10px"
            :show-message="false"
          >
            <el-input v-model="formData.phone" placeholder="请输入手机号">
              <template #prefix>
                <el-dropdown
                  trigger="click"
                  max-height="300px"
                  @command="select"
                >
                  <div style="display: flex; align-items: center">
                    <span style="line-height: 0; min-width: 36px"
                      >+{{ formData.phonecode }}</span
                    >
                    <el-icon style="margin-left: 2px">
                      <CaretBottom />
                    </el-icon>
                  </div>
                  <template #dropdown>
                    <el-dropdown-menu style="padding: 2px 0">
                      <el-dropdown-item
                        v-for="item in options"
                        :command="item.phonecode"
                        >+{{ item.phonecode }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="verification-code label-box" style="margin-top: 10px">
              <Verify
                @success="handleSuccess"
                :mode="'fixed'"
                :isShowImage="false"
                :captchaType="'blockPuzzle'"
                :imgSize="{ width: '290px', height: '155px' }"
              ></Verify>
              <p
                style="color: var(--el-color-primary); cursor: pointer"
                v-if="status.captcha"
              >
                请先完成滑动验证!
              </p>
            </div>
          </el-form-item>
          <el-form-item prop="code">
            <div style="display: flex">
              <el-input v-model="formData.code" placeholder="请输入验证码" />
              <el-button
                style="margin-left: 10px"
                @click="getVerify"
                :disabled="status.disabled"
                >{{
                  status.disabled ? `${status.second}秒后重试` : '获取验证码'
                }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item style="margin: 0">
            <el-button
              type="primary"
              :loading="status.loading"
              @click="submit"
              style="width: 100%"
            >
              登录/注册
            </el-button>
          </el-form-item>
          <div
            style="
              color: var(--el-color-primary);
              cursor: pointer;
              margin-top: 12px;
            "
            @click="status.show = true"
          >
            无法获取验证码？
          </div>
          <div class="agreement">
            <span
              class="check-icon"
              @click="check = !check"
              :class="{ active: check }"
            ></span>
            <p class="text">
              我已阅读并同意
              <a
                href="https://winielts.com/static/appPage/userAgreement-ielts.html"
                target="_blank"
                rel="noopener noreferrer"
                >《羊驼雅思用户服务协议》</a
              >
              和
              <a
                href="https://www.yangtuoedu.com/privacy-policy/?appType=2"
                target="_blank"
                rel="noopener noreferrer"
                >《隐私政策》</a
              >
            </p>
          </div>
        </el-form>
      </div>
    </div>
    <el-dialog
      :z-index="999999"
      v-model="status.show"
      class="tips-dialog"
      width="500"
      align-center
    >
      <img src="/image/yzm.png" width="100%" alt="" />
    </el-dialog>
  </el-dialog>
</template>

<script setup lang="ts">
import {
  ElDialog,
  ElInput,
  ElButton,
  ElFormItem,
  ElForm,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElIcon,
  ElMessage,
} from 'element-plus'
import { CaretBottom } from '@element-plus/icons-vue'
import http from '@/utils/http'
import { ref, reactive, nextTick, watchEffect } from 'vue'
import Verify from '@/components/verifition/Verify.vue'

const rules = {
  phone: { required: true, message: '', trigger: 'blur' },
  code: { required: true, message: '请输入验证码', trigger: 'blur' },
}

const status = reactive({
  loading: false,
  disabled: false,
  show: false,
  captcha: false, // 滑动验证
  second: 60,
})

const check = ref(false)
let countdown: any
let tick: any
const visible = ref(true)
const form = ref()
const options = reactive<any>([])
const formData = reactive<{
  phone?: string
  code?: string
  phonecode: number
  userCode?: string
}>({
  phonecode: 86,
})

const clearCountdown = () => {
  status.second = 60
  status.disabled = false
  clearInterval(countdown)
}
let repData = reactive({
  captchaVerification: null,
})
const handleSuccess = (res: any) => {
  if (res.res.data) {
    let bean = JSON.parse(res.res.data)
    console.log(bean)

    if (bean.success) {
      // sendMsm(res.res.repData)
      if (status.disabled) {
        //正在倒计时
        return
      }
      status.captcha = false
      repData = bean.repData
      send(bean.repData)
    }
  }
}

const send = (data: any) => {
  form.value.validateField('phone').then((valid: any) => {
    if (valid) {
      status.disabled = true
      countdown = setInterval(
        () => (status.second ? status.second-- : clearCountdown()),
        1000
      )
      http
        .post('/api/message/sendSms', {
          ...formData,
          ...data,
        })
        .catch(clearCountdown)
    }
  })
}

const getVerify = () => {
  if (repData.captchaVerification) {
    send(repData)
  } else {
    status.captcha = true
  }
}

const submit = () => {
  if (!check.value) {
    return ElMessage.warning('请阅读并且同意用户协议')
  }
  form.value.validate().then((valid: any) => {
    if (valid) status.loading = true
    http
      .post('/api/auth/loginPhone', formData)
      .then((res) => {
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('expireIn', res.data.expireIn)
        location.reload()
        visible.value = false
      })
      .finally(() => (status.loading = false))
  })
}

http.post('api/message/countryList').then((res) => {
  options.push(...res.data)
})

const select = (val: number) => (formData.phonecode = val)

const close = () => {
  clearInterval(tick)
  clearInterval(countdown)
}
</script>

<style lang="scss">
.tips-dialog {
  padding: 0 !important;
  border-radius: 30px !important;
}

.login {
  padding: 0 !important;
  border-radius: 30px !important;
  background-color: white;

  .el-dialog__header {
    padding-top: 0 !important;
  }

  .el-dialog__body {
    overflow: hidden;
    border-radius: 30px;
  }
}
</style>

<style lang="scss" scoped>
.box {
  padding: 64px;
  background-color: white;
  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 84px;
    .mask {
      position: absolute;
      right: 0;
      width: 83px;
      height: 100%;
      z-index: 1;
      clip-path: polygon(100% 100%, 0% 100%, 0 0%, 0 0);
    }
  }

  .mode {
    display: flex;
    margin-top: -6px;
    justify-content: center;

    > div {
      margin: 0 42px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    > div[active='false'] {
      opacity: 0.3;
      filter: grayscale(1);

      .top {
        display: none;
      }
    }

    .top {
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 16px solid #f8f7fc;
      margin-top: 4px;
    }
  }
  .tips {
    margin-top: 10px;
    text-align: center;
  }

  .phonecode {
    :deep(.el-select__icon) {
      margin: 0;
    }

    :deep(.el-input__validateIcon) {
      display: none;
    }
  }

  .button {
    width: 100%;
    text-align: center;
    color: #fff;
    cursor: pointer;
    background: linear-gradient(
      90deg,
      #886bff 0%,
      var(--el-color-primary) 100%
    );
    border: none;
    border-radius: 10px;
  }
  .agreement {
    display: flex;
    align-items: flex-start;
    gap: 0 6px;
    margin-top: 15px;
    .check-icon {
      cursor: pointer;
      margin-top: 3px;
      width: 15px;
      height: 15px;
      background-size: 100% 100%;
      background-image: url(@/assets/images/icon/weixuanz@2x.png);
      &.active {
        background-image: url(@/assets/images/icon/xuanzhong@2x.png);
      }
    }
    .text {
      flex: 1;
      a {
        color: var(--el-color-primary);
        text-decoration: dashed;
      }
    }
  }
}
</style>
