import { onDeactivated, onUnmounted, ref, type App } from "vue";
import login from "@/components/login";

export default function (app: App<Element>) {
  app.directive("auto-width", {
    beforeMount(el) {
      // 创建一个隐藏的测试元素，用来测量文本宽度
      let tester = document.createElement("div");
      tester.style.position = "absolute";
      tester.style.visibility = "hidden";
      tester.style.fontSize = "18px";

      document.body.appendChild(tester);
      let adjustWidth = () => {
        // 将输入框的内容设置到测试元素中，并获取其宽度
        tester.textContent = el.value;
        let width = tester.getBoundingClientRect().width;

        // 将宽度应用到输入框上，添加一点额外的空间以防止文字紧贴输入框边缘
        el.style.width = `${width}px`;
      };

      // 首次绑定时调整一次宽度
      adjustWidth();

      // 在输入事件上添加监听器，动态调整宽度
      el.addEventListener("input", adjustWidth);

      // 在卸载元素时移除监听器和测试元素
      el._destroyAutoWidth = () => {
        el.removeEventListener("input", adjustWidth);
        document.body.removeChild(tester);
      };
    },
    beforeUnmount(el) {
      // 调用在 beforeMount 阶段保存的销毁函数
      el._destroyAutoWidth();
    },
  });

  app.directive("yt-infinite-scroll", {
    beforeMount(el, binding) {
      let timer: number | null | undefined = null;
      function load() {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          // 检查是否已经滚到底部
          if (
            el.scrollHeight - el.scrollTop - 50 <= el.clientHeight &&
            typeof binding.value.load === "function"
          ) {
            if (binding.value.enabled) return;
            binding.value.load();
          }
        }, 200);
      }

      el.addEventListener("scroll", load);
      onUnmounted(() => {
        el.removeEventListener("scroll", load);
      });
    },
  });

  app.directive("auth-login", (el: HTMLDivElement, binding) => {
    el.onclick = () => {
      if (Boolean(localStorage.getItem("token"))) {
        binding.value();
      } else {
        login();
      }
    };
  });
}
