import { createApp } from "vue";
import login from "./login.vue";

const open = () => {
  const box = document.createElement("div");
  const Instance = createApp(login, {
    onClosed: () => {
      Instance.unmount();
      box.remove();
    }
  });
  document.body.appendChild(box);
  Instance.mount(box);
};

export default open;
