<template>
  <div :class="{ 'ai-hide': visible }">
    <el-config-provider :locale="zhCn">
      <RouterView />
    </el-config-provider>
  </div>
</template>

<script setup lang="ts">
import { zhCn } from "element-plus/es/locale/index.mjs";
import { provide, ref } from "vue";
import { RouterView } from "vue-router";
const visible = ref<boolean>(false);

provide("ai-visible", visible);
</script>

<style lang="less"></style>
