/**
 * 此处可直接引用自己项目封装好的 axios 配合后端联调
 */
import http from "@/utils/http";


//获取验证图片  以及token
export function reqGet(data) {
     
  return http.post("/api/message/captcha/get",data); 
}

//滑动或者点选验证
export function reqCheck(data) { 
    return http.post("/api/message/captcha/check",data);  
}


