import { ElMessage } from "element-plus";

const interceptors = async (response: Response, config: Config = {}) => {
  try {
    const data: JsonFormat = await response.json();
    if (data.status === 404) {
      return Promise.reject(data);
    }
    if (data.code) {
      // TOKEN失效
      if (data.code === 10006) {
        // 清空token,刷新页面
        if (localStorage.getItem("token")) {
          localStorage.clear();
          location.reload();
          return Promise.reject(data);
        }
      }
      if (!config.skipMsg)
        ElMessage.error({
          message: data.msg,
          duration: 3000,
        });
      return Promise.reject(data);
    } else {
      return Promise.resolve(data);
    }
  } catch (error) {
    return Promise.reject("网络请求失败");
  }
};

const token = localStorage.getItem("token");

interface Config {
  headers?: HeadersInit;
  signal?: AbortSignal;
  skipMsg?: boolean;
}

export interface JsonFormat<T = any> {
  /**0-成功  */
  code: number;
  data: T;
  /**服务端提示信息 */
  msg: string;
  status?: 404;
}
export const kSourceBazaar = "ieltsxcx";
export const kClientType = "web";
export const kServiceVersion = "18";
export const kAppClientVersion = "1.0.0";
const headers: any = {
  clientType: kClientType,
  serviceVersion: kServiceVersion,
  clientVersion: kAppClientVersion,
};

const request = {
  get: <T = any>(
    url: string,
    params?: { [key: string]: number | string | boolean | null | undefined },
    config: Config = {}
  ): Promise<JsonFormat<T>> => {
    if (token) headers.token = token;
    return fetch(request.assemble(url, params), {
      headers: { ...headers, ...config.headers },
    }).then((res) => interceptors(res, config));
  },
  post: <T = any>(
    url: string,
    data?: object,
    config: Config = {}
  ): Promise<JsonFormat<T>> => {
    if (token) headers.token = token;
    return fetch(url, {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/json",
        ...config.headers,
      },
      body: JSON.stringify(data),
    }).then((res) => interceptors(res, config));
  },
  upload: <T = any>(
    url: string,
    data?: any,
    config: Config = {}
  ): Promise<JsonFormat<T>> => {
    if (token) headers.token = token;
    return fetch(url, {
      signal: config.signal,
      method: "POST",
      headers: {
        ...headers,
        ...config.headers,
      },
      body: data,
    }).then((res) => interceptors(res, config));
  },
  assemble: (
    url: string,
    params?: { [key: string]: number | string | boolean | null | undefined }
  ): string => {
    if (params) {
      const list = [];
      for (let key in params) {
        if (params[key] || params[key] === 0) {
          list.push(`${key}=${params[key]}`);
        }
      }
      if (list.length) url += (url.includes("?") ? "&" : "?") + list.join("&");
    }
    return url;
  },
  postForm: <T = any>(
    url: string,
    data?: any,
    config: Config = {}
  ): Promise<JsonFormat<T>> => {
    if (token) headers.token = token;
    let body = "";
    if (data) {
      body = Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
    }
    return fetch(url, {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/x-www-form-urlencoded",
        ...config.headers,
      },
      body,
    }).then((res) => interceptors(res, config));
  },
};

export default request;
