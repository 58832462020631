import { login } from '@/components'
import {
  createRouter,
  createWebHistory,
  useRoute,
  type RouteRecordRaw,
} from 'vue-router'
import NProgress from 'nprogress'
import { useBreadcrumbStore } from '@/store/breadcrumb.store'

NProgress.configure({
  showSpinner: false, // 是否显示加载ico;是否在显示加载图标
})

interface MetaType {
  hide?: boolean
  backgroundColor?: string
  // 是否为自定义菜单下拉
  custom?: true
  cacheList?: string[]
  name?: string //名称菜单名称
  keepAlive?: boolean
  query?: any
}
interface MyRoute1 extends Omit<RouteRecordRaw, 'meta'> {
  meta?: MetaType // 根据需求设置额外的meta字段
}
interface MyRoute extends Omit<MyRoute1, 'children'> {
  children?: MyRoute1[] // 根据需求设置额外的children字段
}

const isLogin = Boolean(localStorage.getItem('token'))

const menus: MyRoute[] = [
  {
    path: '',
    name: '首页',
    meta: {
      name: '首页',
      backgroundColor: '#F7F7F7',
    },
    component: () => import('@/views/home/index.vue'),
  },

  {
    path: 'question',
    name: '剑雅真题',
    meta: {
      name: '剑雅真题',
    },
    redirect: {
      name: '剑雅听力',
      query: { type: 1 },
    },
    children: [
      {
        path: 'category',
        name: '剑雅听力',
        meta: {
          name: '剑雅听力',
          backgroundColor: '#F7F7F7',
          query: 1,
        },
        component: () => import('@/views/home/index.vue'),
      },
      {
        path: 'category',
        name: '剑雅阅读',
        meta: {
          name: '剑雅阅读',
          backgroundColor: '#F7F7F7',
          query: 3,
        },
        component: () => import('@/views/home/index.vue'),
      },
      {
        path: 'category',
        name: '剑雅写作',
        meta: {
          name: '剑雅写作',
          backgroundColor: '#F7F7F7',
          query: 2,
        },
        component: () => import('@/views/home/index.vue'),
      },
    ],
    component: () => import('@/views/question/index.vue'),
  },
  {
    path: 'experience',
    name: '机经练习',
    meta: {
      name: '机经练习',
    },
    redirect: {
      name: '口语机经',
      query: { type: 4 },
    },
    children: [
      {
        path: 'category',
        name: '口语机经',
        meta: {
          name: '口语机经',
          backgroundColor: '#F7F7F7',
          query: 4,
        },
        component: () => import('@/views/home/index.vue'),
      },
      {
        path: 'category',
        name: '听力机经',
        meta: {
          name: '听力机经',
          backgroundColor: '#F7F7F7',
          query: 5,
        },
        component: () => import('@/views/home/index.vue'),
      },
      {
        path: 'category',
        name: '阅读机经',
        meta: {
          name: '阅读机经',
          backgroundColor: '#F7F7F7',
          query: 6,
        },
        component: () => import('@/views/home/index.vue'),
      },
      {
        path: 'category',
        name: '写作机经',
        meta: {
          name: '写作机经',
          backgroundColor: '#F7F7F7',
          query: 7,
        },
        component: () => import('@/views/home/index.vue'),
      },
    ],
    component: () => import('@/views/question/index.vue'),
  },
  {
    path: 'home/course',
    redirect: '/course',
    name: '课程资料',
    meta: {
      backgroundColor: '#F7F7F7',
    },
  },
  {
    path: '',
    // name: "学习工具",
    redirect: '/home',
    meta: {
      name: '学习工具',
      backgroundColor: '#F7F7F7',
      query: 3,
    },
    children: [
      {
        path: 'https://winielts.com/exerciselist',
        name: '雅思机考',
        meta: {
          name: '雅思机考',
          backgroundColor: '#F7F7F7',
          query: 3,
        },
      },
      {
        path: 'https://winielts.com/static/H5/ques-and-ans/main/index.html',
        name: '在线测评',
        meta: {
          name: '在线测评',
          backgroundColor: '#F7F7F7',
          query: 4,
        },
      },
    ],
  },
  {
    path: 'home/vip',
    // meta: { name: "VIP" },
    redirect: '/vip',
    name: 'VIP',
  },
  {
    path: 'https://kt.ytaxx.com',
    name: '网校',
    redirect: '/home',
    meta: {
      name: '网校',
      backgroundColor: '#F7F7F7',
      query: 3,
    },
  },
  {
    path: ':questionTypes/:type/:id/:source',
    name: 'Exercises',
    props: true,
    // 使用正则表达式进行参数验证
    beforeEnter: (to, from, next) => {
      // 确保id是一个大于0的数字
      const is = /^[exercises|scripture]/.test(
        to.params.questionTypes as string
      )
      if (is) {
        next()
      } else {
        next('404') // 导航失败
      }
    },
    meta: {
      name: '练习',
      backgroundColor: '#F7F7F7',
      hide: true,
      keepAlive: true,
    },
    component: () => import('@/views/exercises/index.vue'),
  },
  {
    path: 'wekk-list',
    name: '上周考场真题',
    meta: {
      hide: true,
      name: '上周考场真题',
    },
    component: () => import('@/views/wekk-list/index.vue'),
  },
  {
    path: 'collector',
    name: '收藏',
    meta: {
      name: '我的收藏',
      backgroundColor: '#F7F7F7',
      hide: true,
      keepAlive: true,
    },
    component: () => import('@/views/collector/index.vue'),
  },
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 } // 返回顶部作为默认滚动位置
    }
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: () => import('@/components/topLayout/index.vue'),
      children: [
        {
          path: '',
          component: () => import('@/components/layout/index.vue'),
          meta: {
            name: '首页',
          },
          children: [...(menus as unknown as RouteRecordRaw[])],
        },
        {
          path: '/readafter',
          name: '精听',
          props: (route) => ({ id: route.query.id }),
          meta: {
            name: '精听跟读',
            backgroundColor: '#FAFAFA',
            hide: true,
            keepAlive: true,
          },
          component: () => import('@/views/readAfter/index.vue'),
        },
        {
          path: '/vip',
          name: 'vip',
          meta: {
            name: 'VIP',
            keepAlive: true,
            backgroundColor: '#FFF',
            hide: true,
          },
          component: () => import('@/views/vip/index.vue'),
        },
        {
          path: 'course',
          name: 'course',
          meta: {
            name: '雅思课程',
            backgroundColor: '#F7F7F7',
          },
          component: () => import('@/views/course/index.vue'),
        },
        {
          path: 'course/purchase/:id',
          name: 'purchase',
          meta: {
            name: '雅思课程',
            noAuth: true,
            backgroundColor: '#FAFAFA',
            keepAlive: true,
            title: '课程详情',
          },
          component: () => import('@/views/purchase-info/index.vue'),
        },
        {
          path: 'course/info',
          name: 'course-info',
          meta: {
            name: '雅思课程',
            backgroundColor: '#F7F7F7',
          },
          component: () => import('@/views/course/course-info.vue'),
        },
        {
          path: 'course/introduce/:id',
          name: 'course-introduce',
          meta: {
            name: '雅思课程',
            backgroundColor: '#F7F7F7',
          },
          component: () => import('@/views/course-introduce/index.vue'),
        },
      ],
    },
    {
      path: '/:pathMatch(.*)',
      component: () => import('@/views/404.vue'),
    },
  ],
})
router.beforeEach(async (to, from) => {
  NProgress.start() // 进度条开始
  if (to.name === '首页' || isLogin) {
    const toMeta = to.meta as MetaType
    if (toMeta.backgroundColor) {
      document.body.style.background = toMeta.backgroundColor
    }
    return true
  } else if (from.name !== '首页') {
    return { name: '首页' }
  } else {
    login()
    return false
  }
})

interface BreadcrumbType {
  name: String
  children?: BreadcrumbType[]
}

const breadcrumbsStruct: BreadcrumbType[] = [
  {
    name: '首页',
    children: [
      {
        name: '收藏',
        children: [
          {
            name: 'Exercises',
            children: [
              {
                name: '精听',
              },
            ],
          },
          {
            name: '精听',
          },
        ],
      },
      {
        name: '剑雅真题',
        children: [
          {
            name: 'Exercises',
            children: [
              {
                name: '精听',
              },
            ],
          },
        ],
      },
      {
        name: '机经练习',
        children: [
          {
            name: 'Exercises',
          },
        ],
      },
      {
        name: 'Exercises',
        children: [
          {
            name: '精听',
          },
        ],
      },
      {
        name: '精听',
      },
    ],
  },
]

router.afterEach((to, from) => {
  NProgress.done() // 进度条消失
  const routerBreadcrumbsStore = useBreadcrumbStore()
  const restBreadcrumbs = () => {
    routerBreadcrumbsStore.breadcrumbs.length = 0
    routerBreadcrumbsStore.breadcrumbs.push({
      title: '首页',
      path: '/',
      name: '首页',
    })
  }
  if (
    to.name === '首页' ||
    to.path == '/' ||
    to.path == '/home' ||
    routerBreadcrumbsStore.breadcrumbs.length == 0
  ) {
    restBreadcrumbs()
  }

  let toName = to.name
  if (toName) {
    if (['剑雅听力', '剑雅阅读', '剑雅写作'].indexOf(toName as string) > -1) {
      toName = '剑雅真题'
    } else if (
      ['口语机经', '听力机经', '阅读机经', '写作机经'].indexOf(
        toName as string
      ) > -1
    ) {
      toName = '机经练习'
    }
  }
  if (toName) {
    const lastBreadcrumb =
      routerBreadcrumbsStore.breadcrumbs[
        routerBreadcrumbsStore.breadcrumbs.length - 1
      ]
    // 还是当前页面
    if (toName === lastBreadcrumb.name) {
      lastBreadcrumb.path = to.path
      lastBreadcrumb.query = to.query
      lastBreadcrumb.title = (to.meta.name as String) ?? toName
      return
    }

    // 可能跳回了链表的某个页面
    const index = routerBreadcrumbsStore.breadcrumbs.findIndex(
      (item) => item.name == toName
    )
    // 如果当前页面在路由链表中 直接删除后面的页面
    if (index > -1) {
      routerBreadcrumbsStore.breadcrumbs.splice(index + 1)
      const lastBreadcrumb =
        routerBreadcrumbsStore.breadcrumbs[
          routerBreadcrumbsStore.breadcrumbs.length - 1
        ]
      lastBreadcrumb.path = to.path
      lastBreadcrumb.query = to.query
      lastBreadcrumb.title = (to.meta.name as String) ?? toName
      return
    }

    // 根据name 去子页面查询
    let breadcrumb = breadcrumbIndex(
      routerBreadcrumbsStore.breadcrumbs,
      toName as String
    )

    // 如果没有查到 可能是直接跳到了2级页面
    if (!breadcrumb) {
      breadcrumb = secondLevelBreadcrumbIndex(toName as String)
      // 如果二级页面有 重置路由链表
      if (breadcrumb) restBreadcrumbs()
    }

    if (breadcrumb) {
      routerBreadcrumbsStore.breadcrumbs.push({
        title: (to.meta.name as String) ?? toName,
        path: to.path,
        name: breadcrumb.name,
        query: to.query,
      })
    }
  }
})

// 根据name 去子页面查询
const breadcrumbIndex = (breadcrumbs: any[], name: String) => {
  let children: BreadcrumbType[] | undefined = breadcrumbsStruct
  let breadcrumb: BreadcrumbType | undefined
  try {
    breadcrumbs.forEach((index) => {
      if (children) {
        for (const iterator of children) {
          if (index.name === iterator.name) {
            children = iterator.children
          }
        }
      }
    })
    if (children) {
      for (const iterator of children) {
        if (name === iterator.name) {
          breadcrumb = iterator
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
  return breadcrumb
}

const secondLevelBreadcrumbIndex = (name: String) => {
  let children: BreadcrumbType[] = breadcrumbsStruct[0].children!
  let breadcrumb: BreadcrumbType | undefined
  try {
    children.forEach((index) => {
      if (index.name === name) {
        breadcrumb = index
      }
    })
  } catch (error) {
    console.error(error)
  }
  return breadcrumb
}

export { router, menus }
